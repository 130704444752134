import React, { useContext, forwardRef } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

// import { FluentIcon, IconContext } from '../FluentIcon'

import { IconContext } from '../context'

export const NavigationIcon = forwardRef<Path>((props, ref) => {
  const iconContext = useContext(IconContext)

  return (
    <Path
      ref={ref}
      d='M3 17h18a1 1 0 01.117 1.993L21 19H3a1 1 0 01-.117-1.993L3 17h18H3zm0-6l18-.002a1 1 0 01.117 1.993l-.117.007L3 13a1 1 0 01-.117-1.993L3 11l18-.002L3 11zm0-6h18a1 1 0 01.117 1.993L21 7H3a1 1 0 01-.117-1.993L3 5h18H3z'
      fill={iconContext.color}
    />
  )
})

export default NavigationIcon
